import {
  Backdrop, Box, Button, CircularProgress, FormControl,
  Grid, Typography, useTheme, TextField
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from 'react';
import { FaRegCheckCircle } from "react-icons/fa";
import { IoIosHourglass } from "react-icons/io";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import InputMask from 'react-input-mask';
import SearchIcon from '@mui/icons-material/Search';

const ArquivosSiteUsuarias = () => {
  const [valores, setValores] = useState([]);
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const [idUsuaria, setIdusuaria] = useState('');
  const apiHost = process.env.REACT_APP_API_URL_SITE || "http://localhost:8015";

  const handleSearch = () => {

    if (idUsuaria) {
      setLoading(true);
      if (idUsuaria) {
        fetch(`${apiHost}/site/usuaria?numeroOns=${idUsuaria}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
             
            }
          })
          .then(response => response.json())
          .then(data => {
            setData(data);
            const sortedData = data
              .map(item => ({ ...item, id: item.numeroAvc }))
              .sort((b, a) => {
                const [monthA, yearA] = a.periodoContabilInt.split('-').map(Number);
                const [monthB, yearB] = b.periodoContabilInt.split('-').map(Number);
                if (yearA !== yearB) {
                  return yearA - yearB;
                }
                return monthA - monthB;
              });
            setValores(sortedData);
          })
          .catch(error => {
            setLoading(false);
            console.error('Erro ao buscar ID Usuária:', error);
          })
          .finally(() => {
            setLoading(false);
          })
      } else {
        setLoading(false);
        alert('Por favor, preencha o ID Usuária.');
      }
    }

  };


  const columns = [
    { field: "idUsuaria", headerName: "Codigo Ons" },
    {
      field: "transmissoras",
      headerName: "Usuária",
      headerAlign: 'center',
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "competencia",
      headerName: "competencia",
      flex: 1.5,
      headerAlign: 'center',
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Typography >
          {params.row.periodoContabil}
        </Typography>
      ),
    },
    {
      field: "cnpj",
      headerName: "CNPJ",
      flex: 1.5,
      headerAlign: 'center',
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Typography >
{params.row.cnpj ? params.row.cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
            '$1.$2.$3/$4-$5'):'00.000.000/0000-00'}
        </Typography>
      ),
    },
    {
      field: "valorParcela1",
      headerName: "Xml",
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <Typography style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }} >
          {params.row.linkXml ?
          <a href={params.row.linkXml} target="_blank" rel="noopener noreferrer">  
            <FaRegCheckCircle color={colors.greenAccent[500]} /></a>
            : <IoIosHourglass color="red" />}
        </Typography>
      ),
    },
    {
      field: "valorParcela2",
      headerName: "Danfe",
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Typography style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }} >
          {params.row.linkDanfe ?
            <a href={params.row.linkDanfe} target="_blank" rel="noopener noreferrer">  
            <FaRegCheckCircle color={colors.greenAccent[500]} /></a>

            : <IoIosHourglass color="red" />}
        </Typography>
      ),
    },
    {
      field: "valorParcela3",
      headerName: "Boleto",
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <Typography style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }} >
          {params.row.linkBoleto ?
            <a href={params.row.linkBoleto} target="_blank" rel="noopener noreferrer">
              <FaRegCheckCircle color={colors.greenAccent[500]} /> </a>
            : <IoIosHourglass color="red" />}
        </Typography>
      ),
    },
    {
      field: "totalComPisPasep",
      headerName: "Valor Avc",
      flex: 1,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          {params.row.totalOns !== null ? params.row.totalOns.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : (0.0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      ),
    }
  ];

  return (
    <Box m="20px">
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Header title="" subtitle="Integração - Processo de gerenciamento do Site Usuárias" />
        </Grid>
        <Grid item xs={5}>
          <FormControl>
            <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
              <InputMask
                mask="9999"
                value={idUsuaria}
                onChange={(e) => setIdusuaria(e.target.value)}
              >
                {(inputProps) => <TextField {...inputProps} label="Codigo ONS" fullWidth />}
              </InputMask>


              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                onClick={handleSearch}
              >    <SearchIcon /></Button>
            </Box>
          </FormControl>
        </Grid>



      </Grid>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .no-border-bottom": {
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {data.length === 0 ? "" : (
          <DataGrid
            rows={valores}
            columns={columns}
            style={{ margin: '0 auto' }}
            getRowId={(row) => row.periodoContabil}
          />
        )}

      </Box>
    </Box >
  );
};

export default ArquivosSiteUsuarias;
